<template>
  <main v-if="wineType">
    <PageTitleContainer>
      <template #default>
        <PageTitle>{{ wineType.name }}</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'inventory.wine-types.edit', params: { id: wineType.id } }">Modifica</router-link>
      </template>
    </PageTitleContainer>

    <div>
      <p><strong>Name</strong>: {{ wineType.name }}</p>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  $api.fetchWineType(to.params.id)
    .then(res => next(vm => {
      vm.wineType = res?.data;
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      wineType: null,
    };
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
